<template>
  <div class="full-card">
    <template v-if="pageType === 'list'">
      <div class="display-f justify-end">
        <a-button type="primary" icon="plus" @click="openCreate('create')" v-has-permission="['addProblem']">新增问题</a-button>
        <div class="filter margin-l20" @click="filterShow = true"></div>
      </div>
      <a-card style="margin-top: 15px">
        <a-table
            :columns="columns"
            :data-source="list"
            :loading="tableLoading"
            :pagination="pagination"
            rowKey="id"
            bordered
            @change="pageChange"
        >
          <template slot="problemName" slot-scope="text, record">
            <span class="dominant cursor" style="text-decoration: underline" @click="openDetail(record)">{{ text }}</span>
          </template>
          <template slot="problemLevel" slot-scope="text">
            <span v-if="text === 1">紧急&严重</span>
            <span v-if="text === 2">紧急</span>
            <span v-if="text === 3">严重</span>
            <span v-if="text === 4">普通</span>
          </template>
          <template slot="problemStatus" slot-scope="text">
            <span v-if="text === 0">进行</span>
            <span v-if="text === 1">已解决</span>
            <span v-if="text === 2">已关闭</span>
          </template>
          <template slot="id" slot-scope="text, record">
            <img src="@/assets/image/common/edit.png" alt="" @click="openDetail(record)" v-has-permission="['editProblem']">
            <img src="@/assets/image/common/delete.png" alt="" class="margin-l20" @click="delItem(record)" v-has-permission="['deleteProblem']">
          </template>
        </a-table>
      </a-card>
      <a-drawer
          title="过滤器"
          placement="right"
          :visible="filterShow"
          @close="filterShow = false"
          width="500px"
          class="drawer"
      >
        <div class="drawer-cot">
          <a-form-model
              labelAlign="right"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
              :model="filterForm"
              class="drawer-form"
          >
            <a-form-model-item  label="问题级别">
              <a-select v-model="filterForm.problemLevel" placeholder="请选择问题级别">
                <a-select-option :value="1">紧急&严重</a-select-option>
                <a-select-option :value="2">紧急</a-select-option>
                <a-select-option :value="3">严重</a-select-option>
                <a-select-option :value="4">普通</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item  label="状态">
              <a-select v-model="filterForm.problemStatus" placeholder="请选择状态">
                <a-select-option :value="0">进行中</a-select-option>
                <a-select-option :value="1">已解决</a-select-option>
                <a-select-option :value="2">已关闭</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item  label="提报人">
              <a-select v-model="filterForm.creatorId" placeholder="请选择提报人">
                <a-select-option :value="item.id" v-for="(item, index) in memberList" :key="item.id">{{item.staffName }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item  label="被分配者">
              <a-select v-model="filterForm.assignerId" placeholder="请选择提报人">
                <a-select-option :value="item.id" v-for="(item, index) in memberList" :key="item.id">{{item.staffName }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item  label="创建日期">
              <a-range-picker @change="rangeChange" style="width: 100%" />
            </a-form-model-item>

          </a-form-model>
          <div class="drawer-handle">
            <a-button type="primary" icon="filter" @click="onFilter">筛选</a-button>
            <a-button style="margin-left: 10px;" icon="reload"  @click="onReset">重置</a-button>
          </div>
        </div>
      </a-drawer>
      <a-drawer
          title="新增问题"
          placement="right"
          :visible="createShow"
          @close="closeCreate"
          width="700px"
          class="drawer"
          v-if="createShow"
      >
        <div class="drawer-cot">
          <a-form-model
              ref="form"
              labelAlign="right"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 20 }"
              :model="form"
              :rules="rules"
              class="drawer-form"
          >
            <a-row>
              <a-form-model-item label="问题名称" prop="problemName">
                <a-input placeholder="请输入问题名称" v-model="form.problemName" />
              </a-form-model-item>
              <a-form-model-item label="问题级别" prop="problemLevel">
                <a-select v-model="form.problemLevel" placeholder="请选择问题级别">
                  <a-select-option :value="1">紧急&严重</a-select-option>
                  <a-select-option :value="2">紧急</a-select-option>
                  <a-select-option :value="3">严重</a-select-option>
                  <a-select-option :value="4">普通</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="状态" prop="problemStatus">
                <a-select v-model="form.problemStatus" placeholder="请选择状态">
                  <a-select-option :value="0">进行中</a-select-option>
                  <a-select-option :value="1">已解决</a-select-option>
                  <a-select-option :value="2">已关闭</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="被分配者" prop="assignerId">
                <a-select v-model="form.assignerId" placeholder="请选择被分配者">
                  <a-select-option :value="item.id" v-for="(item, index) in memberList" :key="item.id">{{item.staffName }}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="发言" prop="messageContent">
                <QuillEditor v-model="form.messageContent"></QuillEditor>
              </a-form-model-item>
            </a-row>
          </a-form-model>
          <div class="drawer-handle">
            <a-button type="primary" icon="check" @click="onSubmit">确定</a-button>
            <a-button type="default" icon="close" class="margin-l10" @click="closeCreate">取消</a-button>
          </div>
        </div>

      </a-drawer>
    </template>
    <template v-else>
      <Detail :id="id" :problemId="problemId" @cancel="detailCancel" v-if="problemId"></Detail>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getProjectList } from '@/api/project/project'
import { getProblemList, createProblem, deleteProblem } from '@/api/problem/problem'
import { getTaskList, getTaskMember } from '@/api/task/task'
import QuillEditor from '@/components/Editor/QuillEditor'
import Detail from './problemDetail'
import { getProjectMember } from '../../../api/task/task'
export default {
  name: 'problem',
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  components: {
    QuillEditor,
    Detail
  },
  data () {
    return {
      columns: [
        {
          title: '问题名称',
          dataIndex: 'problemName',
          scopedSlots: { customRender: 'problemName' }
        },
        {
          title: '问题级别',
          dataIndex: 'problemLevel',
          scopedSlots: { customRender: 'problemLevel' }
        },
        {
          title: '状态',
          dataIndex: 'problemStatus',
          scopedSlots: { customRender: 'problemStatus' }
        },
        {
          title: '创建时间',
          dataIndex: 'createTime'
        },
        {
          title: '提报人',
          dataIndex: 'creator'
        },
        {
          title: '被分配者',
          dataIndex: 'assigner'
        },
        {
          title: '操作',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' }
        }
      ],
      list: [],
      total: 0,
      createShow: false,
      filterShow: false,
      linkShow: false,
      filesShow: false,
      filterForm: {
        taskId: this.id,
        pageNum: 1,
        pageSize: 10
      },
      form: {
        taskId: this.id
      },
      page: 1,
      roleList: [],
      tableLoading: true,
      pagination: {
        total: 0,
        pageSize: 10
      },
      staffShow: false,
      customerShow: false,
      rules: {
        problemName: [
          { required: true, message: '请输入问题名称', trigger: 'blur' }
        ],
        problemLevel: [
          { required: true, message: '请选择问题级别', trigger: 'change' }
        ],
        problemStatus: [
          { required: true, message: '请选择状态', trigger: 'change' }
        ]
      },
      projectList: [],
      memberList: [],
      checkOptions: [
        { label: '计划内', value: 1 },
        { label: '滞后', value: 2 },
        { label: '提前', value: 3 }
      ],
      color: {
        1: 'font-c3',
        2: 'red',
        3: 'green',
      },
      taskList: [],
      pageType: 'list',
      problemId: ''
    }
  },
  mounted () {
    this.getList()
    this.getProject()
    this.getProjectMember()
    this.getTask()
  },
  methods: {
    getList () {
      return new Promise( (resolve) => {
        this.tableLoading = true
        getProblemList(this.filterForm).then(res => {
          this.list = res.data.list
          this.pagination.total = res.data.total
          this.tableLoading = false
          resolve(res.data)
        })
      })
    },
    pageChange (pages) {
      this.filterForm.pageNum = pages.current
      this.getList()
    },
    openCreate (type, item) {
      this.createShow = true
    },
    closeCreate () {
      this.form = {
        projectId: this.id
      }
      this.createShow = false
    },
    delItem (item) {
      this.$confirm({
        title: '提示',
        content: '确认删除这条数据？',
        centered: true,
        onOk: () => {
          deleteProblem({
            ids: [item.id]
          }).then(() => {
            this.$message.success('删除成功！')
            this.getList()
          })
        }
      })
    },
    onSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          createProblem(this.form).then(() => {
            this.$message.success('操作成功！')
            this.getList()
            this.closeCreate()
          })
        }
      })
    },
    onFilter () {
      this.filterForm.pageNum = 1
      this.getList().then(() => {
        this.filterShow = false
      })
    },
    onReset () {
      this.filterForm = {
        projectId: this.id,
        pageNum: 1,
        pageSize: 10
      }
      this.getList()
    },
    getProject () {
      getProjectList({
        queryParam: {},
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.projectList = res.data.list
      })
    },
    getProjectMember () {
      // getTaskMember({
      //   ids: [this.id]
      // }).then(res => {
      //   this.memberList = res.data
      // })
      getProjectMember({
        projectId: this.form.projectId
      }).then(res => {
        this.memberList = res.data
      })
    },
    rangeChange (date, dateString) {
      this.filterForm.startCreateDate = dateString[0]
      this.filterForm.endCreateDate = dateString[1]
    },
    dateChange (date, dateString) {
      this.form.planEndDate = dateString
    },
    getTask () {
      getTaskList({
        queryParam: {},
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.taskList = res.data.list
      })
    },
    detailCancel () {
      this.pageType = 'list'
    },
    openDetail(item) {
      if (this.authority.viewProblemDetail) {
        this.problemId = item.id
        this.pageType = 'detail'
      }
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      authority: state => state.user.authority,
    }),
  },
}
</script>

<style scoped>

</style>
